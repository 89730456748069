import { useAppDispatch } from '@src/store/hooks';
import { useEffect } from 'react';
import { useAppRoute } from './hooks';
import { setActiveApplication } from '@src/common/state/volatile/slice';
import {
	DefaultAppRouteAuthenticationRedirect,
	DefaultAppRouteUnavailableRedirect,
} from './helpers';
import { WithChildren } from '@src/common/types/react';
import MobilePage from '@src/app/components/MobilePage';
import Logger from '@src/services/logger';
import { LogComponents } from '@src/services/logger/types';
import { AppRouteProps } from './types';
// import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';

const logger = new Logger(LogComponents.Routing, {
	subComponent: 'AppRoute',
	mute: true,
});

const AppRoute = ({
	component = undefined,
	withLayout = false,
	layoutHOC = undefined,
	condition = undefined,
	conditionDependencies = [],
	authenticated = true,
	authenticationRedirect = DefaultAppRouteAuthenticationRedirect,
	unavailableRedirect = DefaultAppRouteUnavailableRedirect,
	children = undefined,
	layoutOptions = undefined,
	componentProps = undefined,
	allowMobile = false,
	role = undefined,
	permissions = undefined,
	permissionsAtLeastOneOf = undefined,
	permissionsAll = undefined,
	internal = undefined,
	requireApplications = true,
}: WithChildren<AppRouteProps>) => {
	const {
		Component,
		isRouteAvailable,
		redirectToAuthentication,
		shouldDisplayMobilePage,
	} = useAppRoute({
		withLayout,
		layoutOptions,
		layoutHOC,
		component,
		condition,
		conditionDependencies,
		authenticated,
		children,
		allowMobile,
		role,
		permissions,
		permissionsAtLeastOneOf,
		permissionsAll,
		requireApplications,
		internal,
	});

	if (redirectToAuthentication) {
		logger.info('Redirecting to authentication');
		return authenticationRedirect;
	}

	if (!isRouteAvailable) {
		logger.info('Route is not available');
		return unavailableRedirect;
	}

	if (shouldDisplayMobilePage) {
		logger.info('Showing mobile page');
		return <MobilePage />;
	}

	return <Component {...componentProps} />;
};

export default AppRoute;

export const UnauthenticatedAppRoute = (props: WithChildren<AppRouteProps>) => (
	<AppRoute {...props} authenticated={false} />
);

export const InternalAppRoute = (props: WithChildren<AppRouteProps>) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setActiveApplication(null));
		// dispatch(setPersistentActiveApplicationId(null));
	}, [dispatch]);

	return <AppRoute {...props} internal={true} />;
};
