import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
	useRefreshApplicationKeysCacheMutation,
	useGetAllApplicationsForInternalUserQuery,
} from '../../api';
import { toast } from 'react-toastify';

export const useAllApplicationsPage = () => {
	const { data: applications, isLoading } =
		useGetAllApplicationsForInternalUserQuery(undefined);

	const [refreshApplicationKeysCache] =
		useRefreshApplicationKeysCacheMutation();

	const [searchTerm, setSearchTerm] = useState('');

	const filteredApplications = useMemo(() => {
		if (!applications) {
			return [];
		}

		return applications.filter(({ name }) => {
			return name.toLowerCase().includes(searchTerm.toLowerCase());
		});
	}, [applications, searchTerm]);

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
		},
		[setSearchTerm]
	);

	const handleRefreshApplicationKeysCache = useCallback(() => {
		if (
			window.confirm(
				'Are you sure you want to refresh the application keys cache? Do not proceed if you are not sure.'
			)
		) {
			refreshApplicationKeysCache().then(() => {
				toast.success('Application keys cache refreshed. Reloading page...', {
					autoClose: 3000,
					onClose: () => {
						window.location.reload();
					},
				});
			});
		}
	}, [refreshApplicationKeysCache]);

	return {
		searchTerm,
		handleChange,
		applications: filteredApplications,
		isLoading,
		handleRefreshApplicationKeysCache,
	};
};
