import { useTestReport } from '@src/modules/test-runs/pages/test-report/hooks';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import { Button, Box } from '@mui/material';
import { useTestReportStyles } from './styles';
import { KeyboardBackspace } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export const TestReport = () => {
	const {
		divRef,
		isLoading,
		onBack,
		lastSuccessfulTestUrl,
		navigateToLastSuccessfulTest,
		isInternalUser,
	} = useTestReport();

	const styles = useTestReportStyles({ isLoading });

	return (
		<Box component="section" css={styles.section}>
			<Box css={styles.header}>
				<Button
					variant="text"
					css={styles.backToTestRunsButton}
					onClick={onBack}
				>
					<KeyboardBackspace /> Back to test runs
				</Button>

				<If condition={!!lastSuccessfulTestUrl && isInternalUser}>
					<Link
						to={lastSuccessfulTestUrl}
						onClick={navigateToLastSuccessfulTest}
					>
						<Button variant="text" css={styles.lastSuccessfulTestButton}>
							Go to last successful test
						</Button>
					</Link>
				</If>
			</Box>

			<If condition={isLoading}>
				<LoadingCircularProgress styles={styles.loading}>
					Loading report...
				</LoadingCircularProgress>
			</If>

			<div id="report-placeholder" css={styles.reportDiv} ref={divRef} />
		</Box>
	);
};
