import { Box, TablePagination, Typography } from '@mui/material';
import { TestRunList } from '../../components/TestRunList/TestRunList';
import { TestSuiteRun } from '../../types';
import { useTestRunsPageStyles } from './styles';
import { useMultiAppTestRuns } from './hooks/useMultiAppTestRuns';
import { ShowLastTestRunsCheckbox } from '../../components/ShowLastTestRunsCheckbox/ShowLastTestRunsCheckbox';
// import { TestRunsHeader } from '../../components/TestRunsHeader/TestRunsHeader';

export const MultiAppTestRuns = () => {
	let {
		testRuns,
		isFetching,
		isTestRunsLoadingSuccess,
		page,
		handleChangePage,
		handleChangeRowsPerPage,
		labelDisplayedRows,
		rowsPerPage,
		options,
		showLastTestRuns,
		handleSetShowLastTestRuns,
		// filterAutocompleteInputsData,
	} = useMultiAppTestRuns();

	const styles = useTestRunsPageStyles();

	return (
		<Box component="section" css={styles.section}>
			<Typography variant="h1" gutterBottom>
				Test Suite Runs
			</Typography>

			<ShowLastTestRunsCheckbox
				checked={showLastTestRuns}
				onChange={handleSetShowLastTestRuns}
			/>

			{/* <TestRunsHeader
				filterAutocompleteInputsData={filterAutocompleteInputsData}
			/> */}

			<TestRunList
				isTestRunsLoadingSuccess={isFetching ? false : isTestRunsLoadingSuccess}
				testRuns={isFetching ? [] : (testRuns as TestSuiteRun[])}
				listStyle={styles.list}
				options={options}
			/>

			<TablePagination
				component="div"
				count={-1}
				page={page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelDisplayedRows={labelDisplayedRows}
				sx={{ overflow: 'hidden' }}
			/>
		</Box>
	);
};
