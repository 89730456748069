import { useSelector } from 'react-redux';
import {
	selectActiveApplication,
	selectApplicationsInfo,
	selectAppName,
	selectAuthenticationInfo,
	selectIsAuthenticated,
	selectIsLoggingIn,
	selectLayoutOptions,
	selectRedirectToAfterAuthentication,
} from './selectors';

export const useLayoutOptions = () => useSelector(selectLayoutOptions);

export const useIsAuthenticated = () => useSelector(selectIsAuthenticated);

export const useAuthenticationInfo = () =>
	useSelector(selectAuthenticationInfo);

export const useIsInternalUser = () =>
	useSelector(selectAuthenticationInfo)?.user?.internal;

export const useApplicationsInfo = () => useSelector(selectApplicationsInfo);

export const useIsLoggingIn = () => useSelector(selectIsLoggingIn);

export const useAppName = () => useSelector(selectAppName);

export const useActiveApplication = () => useSelector(selectActiveApplication);
export const useRedirectToAfterAuthentication = () =>
	useSelector(selectRedirectToAfterAuthentication);
