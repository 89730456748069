import {
	APITestSuiteRun,
	APITestSuiteRunStatus,
} from '@src/modules/test-runs/api/types';

let id = 0;

const date = new Date().toISOString();

const yesterday = new Date(date);
yesterday.setDate(yesterday.getDate() - 1);

//yesterday date to mock the time difference
const yesterdayDate = yesterday.toISOString();

export const REPORT_URL_MOCK = 'data/report.html';

export const getTestRunsMock: APITestSuiteRun[] = [
	{
		id: (++id).toString(),
		mode: 'auto-heal',
		uploadsCompleted: true,
		branch: 'main',
		commitHash: 'commitHash1',
		tags: ['pre-load', 'daily', 'local'],
		name: 'pull request name 1',
		environment: 'environment',
		passed: 3,
		failed: 2,
		healed: 1,
		startedAt: date,
		endedAt: date,
		status: APITestSuiteRunStatus.Failed,
		runtimeVersion: '1.0.0',
	},
	{
		id: (++id).toString(),
		mode: 'auto-heal',
		uploadsCompleted: true,
		branch: 'main',
		commitHash: 'commitHash2',
		tags: ['daily', 'general'],
		name: 'pull request name 2',
		environment: 'environment1',
		passed: 2,
		failed: 0,
		healed: 0,
		startedAt: yesterdayDate,
		endedAt: date,
		status: APITestSuiteRunStatus.Passed,
		runtimeVersion: '1.0.0',
	},
	{
		id: (++id).toString(),
		mode: 'normal',
		uploadsCompleted: false,
		branch: 'main',
		commitHash: 'commitHash4',
		tags: ['daily'],
		name: 'pull request name 3',
		environment: 'environment1',
		passed: 0,
		failed: 0,
		healed: 0,
		startedAt: date,
		endedAt: null,
		status: APITestSuiteRunStatus.Running,
		runtimeVersion: '1.0.0',
	},
	{
		id: (++id).toString(),
		mode: 'auto-heal',
		uploadsCompleted: true,
		branch: 'main',
		commitHash: 'commitHash4',
		tags: ['daily', 'local'],
		name: 'pull request name 4',
		environment: 'environment1',
		passed: 5,
		failed: 0,
		healed: 2,
		pullRequestURL: 'www.pullrequest.com',
		startedAt: date,
		endedAt: date,
		status: APITestSuiteRunStatus.Passed,
		runtimeVersion: '1.0.0',
	},
	{
		id: (++id).toString(),
		mode: 'auto-heal',
		uploadsCompleted: true,
		branch: 'main',
		commitHash: 'commitHash4',
		tags: ['daily', 'local'],
		name: 'pull request name 4',
		environment: 'environment1',
		passed: 5,
		failed: 0,
		healed: 2,
		pullRequestURL: 'www.pullrequest.com',
		startedAt: date,
		endedAt: date,
		status: APITestSuiteRunStatus.Passed,
		runtimeVersion: '1.0.0',
	},
];
