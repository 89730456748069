import api from '@src/api';
import { TestSuiteRun, TestSuiteRunPagination } from '../types';
import { transformAPIGetTestRunsResponse } from '@src/modules/test-runs/api/transformers';
import {
	LastSuccessfulTestReportURLData,
	SingleTestRunStatusData,
} from './types';

export const testRunsAPI = api
	.enhanceEndpoints({ addTagTypes: ['TestRuns', 'MultiAppTestRuns'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			// [getTestRuns]
			getTestRuns: builder.query<TestSuiteRun[], void>({
				query: () => ({
					url: `test-runs`,
				}),
				providesTags: ['TestRuns'],
				transformResponse: transformAPIGetTestRunsResponse,
			}),
			// [getTestRuns]
			getMultiAppTestRuns: builder.query<
				TestSuiteRun[],
				{ pagination: TestSuiteRunPagination; showLastTestRuns?: boolean }
			>({
				query: ({ pagination, showLastTestRuns }) => ({
					url: `test-runs/multi-app`,
					method: 'POST',
					body: {
						pagination,
						query: { order: { createdAt: 'DESC' } },
						showLastTestRuns,
					},
				}),
				providesTags: (result, error, { pagination }) => [
					{
						type: 'MultiAppTestRuns',
						id: pagination.page * pagination.resultsPerPage,
					},
				],
				transformResponse: transformAPIGetTestRunsResponse,
			}),
			unhideReport: builder.mutation<boolean, { id: string }>({
				query: ({ id }) => ({
					url: `test-runs/${id}`,
					method: 'PATCH',
					body: { isHidden: false },
				}),
				invalidatesTags: ['TestRuns'],
			}),
			// [getTestRunReportURL]
			getTestRunReportURL: builder.query<
				{ reportURL: string; altToken: string; testRunAppId: string },
				{ id: string }
			>({
				query: ({ id }) => ({
					url: `test-runs/${id}/report-url`,
				}),
			}),
			// [getTraceURL]
			getTraceURL: builder.query<
				string,
				{ id: string; testId: string; fileName?: string }
			>({
				query: ({ id, testId, fileName }) => ({
					url: `test-runs/${id}/${testId}/trace-url${
						fileName ? `?fileName=${fileName}` : ''
					}`,
				}),
			}),
			// [getLastSuccessfulTestRun]
			getLastSuccessfulTestRun: builder.query<
				LastSuccessfulTestReportURLData,
				{ id: string; testId: string }
			>({
				query: ({ id, testId }) => ({
					url: `test-runs/${id}/${testId}/last-successful-test`,
				}),
			}),
			// [getLastSuccessfulTestRun]
			getSingleTestRunStatus: builder.query<
				SingleTestRunStatusData,
				{ id: string; testId: string }
			>({
				query: ({ id, testId }) => ({
					url: `test-runs/${id}/${testId}/status`,
				}),
			}),
		}),
	});

export const {
	useGetTestRunsQuery,
	useGetMultiAppTestRunsQuery,
	useUnhideReportMutation,
	useGetTestRunReportURLQuery,
	useLazyGetTraceURLQuery,
	useGetLastSuccessfulTestRunQuery,
	useGetSingleTestRunStatusQuery,
} = testRunsAPI;
