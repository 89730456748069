import { useGetMultiAppTestRunsQuery } from '@src/modules/test-runs/api';
import { TestSuiteRunListOptions } from '@src/modules/test-runs/types';
import { useCallback, useMemo, useState } from 'react';
import { TestRunFilterNames, useFilterTestRuns } from './useFilterTestRuns';
import { InternalApplicationRoutes } from '@src/modules/internal/service';

export const useMultiAppTestRuns = () => {
	const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
		usePagination();
	const [showLastTestRuns, setShowLastTestRuns] = useState(false);

	const {
		data: testRuns,
		isSuccess: isTestRunsLoadingSuccess,
		isLoading,
		isFetching,
	} = useGetMultiAppTestRunsQuery({
		pagination: { page: page + 1, resultsPerPage: rowsPerPage },
		showLastTestRuns,
	});

	const { filterAutocompleteInputsData, filteredTestRuns } = useFilterTestRuns({
		testRuns,
		excludeFilters: [TestRunFilterNames.Commit, TestRunFilterNames.Tags],
	});

	const labelDisplayedRows = useCallback(({ from, to, count }) => {
		return `${from}-${to === -1 ? count : to}`;
	}, []);

	const options: TestSuiteRunListOptions = useMemo(
		() => ({
			displayApplicationName: true,
			referrer: InternalApplicationRoutes.testRuns.absolutePath,
		}),
		[]
	);

	const handleSetShowLastTestRuns = useCallback(
		(value: boolean) => {
			setShowLastTestRuns(value);
			if (value) {
				handleChangePage(undefined, 0);
			}
		},
		[handleChangePage]
	);

	return {
		testRuns: filteredTestRuns,
		isLoading,
		isFetching,
		isTestRunsLoadingSuccess,
		page,
		rowsPerPage,
		handleChangePage,
		handleChangeRowsPerPage,
		labelDisplayedRows,
		options,
		filterAutocompleteInputsData,
		showLastTestRuns,
		handleSetShowLastTestRuns,
	};
};

const usePagination = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return {
		page,
		rowsPerPage,
		handleChangePage,
		handleChangeRowsPerPage,
	};
};
