import { useEffect } from 'react';
import { invalidateAllTags } from '@src/api';
import { setActiveApplication } from '@src/common/state/volatile/slice';
import { toast } from 'react-toastify';
import { testRunsRoutes } from '@src/modules/test-runs/routes/routes';
import { useAppDispatch } from '@src/store/hooks';
import { useGetApplicationsQueryState } from '@src/modules/settings/service';
import { useApplicationsInfo } from '@src/common/state/volatile/hooks';
import { useNavigate } from 'react-router-dom';
import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';

export const useTestRunReportUrlApplicationChanged = ({
	reportURLData,
	reportUrlLoadedSuccessfully,
}: {
	reportURLData: { testRunAppId: string };
	reportUrlLoadedSuccessfully: boolean;
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { activeApplication } = useApplicationsInfo();
	const { data: applications = [] } = useGetApplicationsQueryState();

	useEffect(() => {
		// if test run belongs to different application, switch active application
		if (
			reportUrlLoadedSuccessfully &&
			reportURLData.testRunAppId !== activeApplication.id
		) {
			// find test run's app in application query cache
			const newApp = applications.find(
				({ id }) => id === reportURLData.testRunAppId
			);

			// if app exists for this user, switch the application,
			// otherwise, show an error message and go to current app's test runs page
			if (newApp) {
				dispatch(invalidateAllTags());
				dispatch(setPersistentActiveApplicationId(newApp.id));
				dispatch(setActiveApplication(newApp));
			} else {
				toast.error(
					"You don't have access to this report because you're not a member of the project. Ask the person who shared the link to add you first."
				);
				navigate(testRunsRoutes.testRuns.absolutePath);
			}
		}
	}, [
		dispatch,
		reportUrlLoadedSuccessfully,
		navigate,
		reportURLData,
		applications,
		activeApplication,
	]);
};
