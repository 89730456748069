import { css } from '@emotion/react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';

export const useAllApplicationsPageStyles = () => {
	const theme = useTheme();

	return useMemo(() => {
		const section = css({
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			padding: theme.spacing(4),
		});

		const topBar = css({
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
			alignItems: 'center',
		});
		const searchField = css({
			flexGrow: 1,
		});

		return {
			section,
			topBar,
			searchField,
		};
	}, [theme]);
};
