import {
	useActiveApplication,
	useAuthenticationInfo,
} from '@src/common/state/volatile/hooks';
import { useMemo } from 'react';

export const useDefaultPage = () => {
	const authenticationInfo = useAuthenticationInfo();
	const activeApplication = useActiveApplication();

	// We display the internal page as default only if the user is authenticated and is an internal user,
	// and currently doesn't have any active application
	const displayInternalDefaultPage = useMemo(() => {
		return (
			authenticationInfo.authenticated &&
			!!authenticationInfo.user?.internal &&
			!activeApplication
		);
	}, [authenticationInfo, activeApplication]);

	return { displayInternalDefaultPage };
};
