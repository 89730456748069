import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
	Alert,
	Box,
	Button,
	Divider,
	IconButton,
	InputAdornment,
	Snackbar,
	TextField,
	Typography,
} from '@mui/material';
import { PeriodicTestRunsTable } from './components/PeriodicTestsTable';
import { usePeriodicTestsPage } from './hooks/usePeriodicTestsPage';
import TimeDisplay from './components/TimeDisplay';

export const PeriodicTestsPage: React.FC = () => {
	const {
		snackbarOpen,
		appName,
		setAppName,
		isAddingApp,
		snackbarMessage,
		searchQuery,
		setSearchQuery,
		handleAddAppClick,
		handleCancelAddUser,
		onCreatePeriodicTest,
		handleCloseSnackbar,
		filteredPeriodicTestRuns,
	} = usePeriodicTestsPage();

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					margin: '10px',
					alignItems: 'center',
					transition: 'width 0.3s ease', // Smooth transition
					width: isAddingApp ? '100%' : 'auto',
				}}
			>
				<Box>
					<Typography variant="h4">Current Time</Typography>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
							justifyContent: 'flex-start',
						}}
					>
						<TimeDisplay message="LOCAL TIME" />
						<Divider orientation="vertical" flexItem />
						<TimeDisplay message="UTC TIME" useUTC={true} />
					</Box>
				</Box>
				<Choose>
					<When condition={isAddingApp}>
						<Button
							startIcon={<AddIcon />}
							variant="contained"
							onClick={handleAddAppClick}
							sx={{
								transition: 'width 0.3s ease',
							}}
						>
							Add App
						</Button>
					</When>
					<Otherwise>
						<Box
							sx={{
								display: 'flex',
								transition: 'all 0.3s ease',
							}}
						>
							<TextField
								label="Add new periodic job"
								placeholder="App Name"
								variant="outlined"
								fullWidth
								margin="dense"
								value={appName}
								onChange={(e) => setAppName(e.target.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleCancelAddUser}>
												<CloseIcon />
											</IconButton>
											<IconButton onClick={onCreatePeriodicTest}>
												<CheckIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Box>
					</Otherwise>
				</Choose>
			</Box>

			<TextField
				label="Search for APP..."
				variant="outlined"
				fullWidth
				margin="dense"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)} // Update search query
			/>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{snackbarMessage}
				</Alert>
			</Snackbar>

			{/* Pass filtered users to UsersTable */}
			<PeriodicTestRunsTable periodicTestRuns={filteredPeriodicTestRuns} />
		</Box>
	);
};
