import { useCallback, useMemo } from 'react';
import { UserStory, UserStoryStatus } from '../../types';
import { getStoryLastActivityNote } from '../../helpers';
import {
	TestGenerationPRJobStatus,
	TestGenerationStatus,
	useAuthorizeTestGenerationsMutation,
	useLazyGetTraceSignedURLQuery,
} from '@src/modules/test-generations/service';
import { useWindowOpenHandler } from '@src/common/hooks/useWindowOpenHandler';
import Config from '@src/config';
import { ApplicationRoutes } from '@src/app/router/routes';
import {
	useActiveApplication,
	useAuthenticationInfo,
} from '@src/common/state/volatile/hooks';
import { InternalUserPermissions } from '@src/modules/authentication/service';

export const useStoryStatusBar = (story: UserStory) => {
	const { user } = useAuthenticationInfo();

	const shouldDisplayTestGenerationFailedForInternalUser = useMemo(() => {
		// display icon that test generation failed in verifying process pending
		// for internal user, (isSuccess can be undefined or null, check if it is false)
		return (
			user.internal &&
			story.status === UserStoryStatus.Verifying &&
			story.recentGeneration.isSuccess === false
		);
	}, [user, story]);

	const { storyLastActivityNote, linkToPR } = useMemo(() => {
		return {
			storyLastActivityNote: getStoryLastActivityNote(story),
			linkToPR: story.recentGeneration?.pullRequestURL,
		};
	}, [story]);

	const openPR = useWindowOpenHandler(linkToPR, {
		preventDefault: true,
		stopPropagation: true,
	});

	const [getTraceSignedURL] = useLazyGetTraceSignedURLQuery();

	const {
		shouldDisplayAuthorizeButton,
		shouldDisplayPRGenerateButton,
		isGeneratePRLoading,
		shouldDisplayTraceOnVerifying,
		handleAuthorizeTestGenerations,
		handleAuthorizePfFailedTestGenerations,
		isAuthorizeTestGenerationLoading,
	} = useAuthorizeTestGeneration({ story });

	const shouldDisplayTrace = useMemo(() => {
		return (
			([
				TestGenerationStatus.Completed,
				TestGenerationStatus.Failed,
				TestGenerationStatus.PRMerged,
				TestGenerationStatus.PRClosed,
			].includes(story.recentGeneration?.status) ||
				shouldDisplayTraceOnVerifying) &&
			story.recentGeneration?.hasTrace
		);
	}, [story, shouldDisplayTraceOnVerifying]);

	// this currently downloads the trace file and then instructs the user
	// to open the trace viewer manually
	const openTrace = useCallback(
		(e) => {
			e.stopPropagation();
			e.preventDefault();
			getTraceSignedURL({
				userStoryId: story.id,
				testGenerationId: story.recentGeneration?.id.toString(),
			}).then((result) => {
				// downloadFileByUrl(result.data, 'trace.zip');
				// toast.success(TraceDownloadSuccessToastContent, {
				// 	toastId: TraceDownloadSuccessToastId,
				// 	autoClose: false,
				// 	closeOnClick: false,
				// });
				window.open(
					`${
						Config.appUrl
					}/#${ApplicationRoutes.traceViewer.make()}?url=${encodeURIComponent(
						result.data
					)}&inline=true`, // as we are opening a new tab, we instruct the trace viewer to open in the current tab
					'_blank'
				);
			});
		},
		[getTraceSignedURL, story]
	);

	const { iterationLogURL, agentLogURL, testArtifactsURL, internalTestId } =
		useTestGenerationInternalUserOptions({
			story,
		});

	return {
		shouldDisplayTestGenerationFailedForInternalUser,
		shouldDisplayAuthorizeButton,
		shouldDisplayPRGenerateButton,
		isGeneratePRLoading,
		handleAuthorizeTestGenerations,
		handleAuthorizePfFailedTestGenerations,
		isAuthorizeTestGenerationLoading,
		shouldDisplayTrace,
		storyLastActivityNote,
		hasPR: !!linkToPR,
		openTrace,
		openPR,
		iterationLogURL,
		agentLogURL,
		testArtifactsURL,
		internalTestId,
	};
};

const useAuthorizeTestGeneration = ({ story }: { story: UserStory }) => {
	const authInfo = useAuthenticationInfo();

	const [authorizeTestGenerations, { isLoading: isAuthorizeRequestPending }] =
		useAuthorizeTestGenerationsMutation();

	const isAuthorizeTestGenerationLoading = useMemo(() => {
		const recentTestGenerationPRJobActive =
			story.recentGeneration?.prJobStatus === TestGenerationPRJobStatus.Active;

		return recentTestGenerationPRJobActive || isAuthorizeRequestPending;
	}, [story, isAuthorizeRequestPending]);

	const {
		shouldDisplayAuthorizeButton,
		shouldDisplayTraceOnVerifying,
		shouldDisplayPRGenerateButton,
	} = useMemo(() => {
		const isStoryVerifying = story.status === UserStoryStatus.Verifying;

		const isAllowedPermission =
			authInfo.user.internal &&
			authInfo.user.internalPermissions.includes(
				InternalUserPermissions.AuthorizeTestGenerations
			);

		const shouldDisplay = isStoryVerifying && isAllowedPermission;

		const shouldDisplayPRGenerateButton =
			story.status === UserStoryStatus.Completed &&
			!story.recentGeneration.pullRequestURL;

		return {
			shouldDisplayAuthorizeButton: shouldDisplay,
			shouldDisplayTraceOnVerifying: shouldDisplay,
			shouldDisplayPRGenerateButton,
		};
	}, [story, authInfo]);

	const handleAuthorizeTestGenerations = useCallback(
		(e, generatePr) => {
			// prevent user story form from opening
			e.stopPropagation();

			authorizeTestGenerations({
				userStoryId: story.id,
				testGenerationId: story.recentGeneration.id,
				// authorize all test generations for internal user or only completed with no pr link for all users
				generatePr,
			});
		},
		[story, authorizeTestGenerations]
	);

	return {
		handleAuthorizeTestGenerations: (e) =>
			handleAuthorizeTestGenerations(e, false),
		handleAuthorizePfFailedTestGenerations: (e) =>
			handleAuthorizeTestGenerations(e, true),
		shouldDisplayTraceOnVerifying,
		shouldDisplayPRGenerateButton,
		isGeneratePRLoading: isAuthorizeTestGenerationLoading,
		isAuthorizeTestGenerationLoading,
		shouldDisplayAuthorizeButton,
	};
};

const useTestGenerationInternalUserOptions = ({
	story,
}: {
	story: UserStory;
}) => {
	const application = useActiveApplication();

	const authInfo = useAuthenticationInfo();
	const isInternalUser = authInfo.user.internal;

	const { iterationLogURL, agentLogURL, testArtifactsURL, internalTestId } =
		useMemo(() => {
			// only for internal users, no additional permissions
			if (!isInternalUser) {
				return {
					iterationLogURL: null,
					agentLogURL: null,
					testArtifactsURL: null,
					internalTestId: null,
				};
			}

			// only for completed test generations (note: could also use defined completedAt field?)
			if (
				![
					UserStoryStatus.Verifying,
					UserStoryStatus.Completed,
					UserStoryStatus.Failed,
					UserStoryStatus.PRClosed,
					UserStoryStatus.PRMerged,
				].includes(story.status)
			) {
				return {
					iterationLogURL: null,
					agentLogURL: null,
					testArtifactsURL: null,
					internalTestId: null,
				};
			}

			// construct and return the URL
			const { runId, internalTestId, createdAt } = story.recentGeneration;
			const iterationLogURL = `https://storage.cloud.google.com/checksum-test-generation-logs/${
				new Date(createdAt).toISOString().split('T')[0]
			}/${application.name}_${runId}_${internalTestId}.log.json`;

			const bucketURL = `checksum-tests-generated/${application.name}/${runId}`;
			const agentLogURL = `https://storage.cloud.google.com/${bucketURL}/test-data/log/${internalTestId}.log`;
			const testArtifactsURL = `https://console.cloud.google.com/storage/browser/${bucketURL}`;
			return { iterationLogURL, agentLogURL, testArtifactsURL, internalTestId };
		}, [story, application, isInternalUser]);

	return { iterationLogURL, agentLogURL, testArtifactsURL, internalTestId };
};
