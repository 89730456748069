import { Checkbox, FormControlLabel } from '@mui/material';

interface ShowLastTestRunsCheckboxProps {
	checked: boolean;
	onChange: (checked: boolean) => void;
}

export const ShowLastTestRunsCheckbox = ({
	checked,
	onChange,
}: ShowLastTestRunsCheckboxProps) => {
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={checked}
					onChange={(e) => onChange(e.target.checked)}
					color="primary"
				/>
			}
			label="Show last test runs"
		/>
	);
};
