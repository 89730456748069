import { SerializedStyles } from '@emotion/react';
import { Box, Button, List } from '@mui/material';
import { isNotEmptyArray } from '@src/common/helpers/arrays';
import { TestSuiteRun, TestSuiteRunListOptions } from '../../types';
import { useTestRunsListStyles } from '@src/modules/test-runs/components/TestRunList/styles';
import { TestRunListSkeleton } from '@src/modules/test-runs/components/TestRunList/TestRunListSkeleton';
import { TestRunListItem } from '@src/modules/test-runs/components/TestRunListItem/TestRunListItem';
import { Link } from 'react-router-dom';
import { testRunsRoutes } from '@src/modules/test-runs/routes/routes';
import { useTestRunList } from './hooks';

export function TestRunList({
	testRuns,
	listStyle,
	isTestRunsLoadingSuccess,
	options = {},
}: {
	testRuns: TestSuiteRun[];
	listStyle: SerializedStyles;
	isTestRunsLoadingSuccess: boolean;
	options?: Partial<TestSuiteRunListOptions>;
}) {
	const { onClick, onAppNameClick } = useTestRunList(testRuns);
	const styles = useTestRunsListStyles({ listStyle });
	return (
		<Choose>
			<When condition={isNotEmptyArray(testRuns)}>
				<List css={styles.list}>
					{testRuns.map((testRun, index) => (
						<Box key={testRun.id}>
							<AppNameButton
								options={options}
								testRun={testRun}
								onClick={onAppNameClick}
							/>
							<Link
								key={testRun.id}
								to={testRunsRoutes.testRun.make({
									id: testRun.id,
								})}
								onClick={(e) => onClick(testRun)}
								style={{ color: 'inherit' }}
								state={{ referrer: options?.referrer }}
							>
								<TestRunListItem
									key={testRun.id}
									testRun={testRun}
									options={options}
								/>
							</Link>
						</Box>
					))}
				</List>
			</When>

			<When condition={isTestRunsLoadingSuccess}>
				No test runs matching these filters
			</When>

			<Otherwise>
				<TestRunListSkeleton count={4} listStyle={styles.list} />
			</Otherwise>
		</Choose>
	);
}

function AppNameButton({
	options,
	testRun,
	onClick,
}: {
	options: Partial<TestSuiteRunListOptions>;
	testRun: TestSuiteRun;
	onClick: (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		appName: string
	) => void;
}) {
	return (
		<If
			condition={
				options.displayApplicationName && !!testRun.customerApplication?.name
			}
		>
			<Link
				// variant="text"
				to={testRunsRoutes.testRuns.make(
					{},
					`?app=${testRun.customerApplication.name}`
				)}
				onClick={(e) => onClick(e, testRun.customerApplication.name)}
			>
				<Button>{testRun.customerApplication?.name}</Button>
			</Link>
		</If>
	);
}
