import { SerializedStyles } from '@emotion/react';
import { List } from '@mui/material';
import { TestRunListItemSkeleton } from '@src/modules/test-runs/components/TestRunListItem/TestRunListItemSkeleton';

export function TestRunListSkeleton({
	count = 3,
	listStyle,
}: {
	count?: number;
	listStyle?: SerializedStyles[];
}) {
	return (
		<List css={listStyle}>
			{Array.from({ length: count }).map((_, index) => (
				<TestRunListItemSkeleton key={index} />
			))}
		</List>
	);
}
