import {
	Box,
	Button,
	Chip,
	ListItem,
	ListItemButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	TestSuiteRun,
	TestSuiteRunListOptions,
	TestSuiteRunStatus,
} from '../../types';
import {
	useTestRunListItemBottomBarIndicatorStyles,
	useTestRunListItemStyles,
} from '@src/modules/test-runs/components/TestRunListItem/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { ReactComponent as GitBranchIcon } from '@assets/icons/GitBranch.svg';

import { useTestRunListItem } from '@src/modules/test-runs/components/TestRunListItem/useTestRunListItem';
import {
	CheckCircle,
	Error,
	GitHub,
	HourglassEmpty,
} from '@mui/icons-material';

export function TestRunListItem({
	testRun,
	options = {},
}: {
	testRun: TestSuiteRun;
	options?: Partial<TestSuiteRunListOptions>;
}) {
	const styles = useTestRunListItemStyles(testRun);

	const {
		duration,
		branch,
		name,
		successfulResults,
		failedResults,
		startedAt,
		openPR,
		shouldDisplayResults,
		isInternalUser,
		markNotHidden,
		isUnhideReportLoading,
		runtimeVersion,
	} = useTestRunListItem(testRun);

	return (
		<ListItem css={styles.listItem}>
			<ListItemButton css={styles.listItemButton}>
				<Box css={styles.contentWrapper}>
					<Box /* header */>
						<Stack css={styles.title}>
							<Choose>
								<When condition={testRun.status === TestSuiteRunStatus.Running}>
									<Tooltip title="Running">
										<HourglassEmpty />
									</Tooltip>
								</When>
								<When condition={testRun.status === TestSuiteRunStatus.Passed}>
									<CheckCircle />
								</When>
								<When condition={testRun.status === TestSuiteRunStatus.Failed}>
									<Error />
								</When>
							</Choose>
							<Typography component="div">
								{name}
								<If condition={isInternalUser && testRun.isHidden}>
									<Chip
										label="Hidden, click to expose"
										color="error"
										size="small"
										css={styles.hiddenChip}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											markNotHidden();
										}}
										disabled={isUnhideReportLoading}
									/>
								</If>
							</Typography>
						</Stack>
					</Box>

					<Box css={styles.bottomBar}>
						<BottomBarIndicator>
							<AccessTimeIcon />
							{startedAt}
						</BottomBarIndicator>

						<If condition={!!duration}>
							<BottomBarIndicator>
								<TimerOutlinedIcon />
								{duration}
							</BottomBarIndicator>
						</If>

						<BottomBarIndicator>
							<GitBranchIcon />
							{branch}
						</BottomBarIndicator>

						<If condition={isInternalUser && !!runtimeVersion}>
							<BottomBarIndicator>
								<DirectionsRunIcon />
								{runtimeVersion}
							</BottomBarIndicator>
						</If>

						<If condition={!!testRun.pullRequestURL}>
							<BottomBarIndicator>
								<Button css={styles.buttonIcon} onClick={openPR}>
									<GitHub />
									<Typography variant="subtitle2" css={styles.buttonIconText}>
										Pull request
									</Typography>
								</Button>
							</BottomBarIndicator>
						</If>
					</Box>
				</Box>

				<If condition={shouldDisplayResults}>
					<Box css={styles.resultsWrapper}>
						<Typography variant="caption" css={styles.successResultsCount}>
							<CheckIcon />
							{successfulResults}
						</Typography>

						<Typography variant="caption" css={styles.errorResultsCount}>
							<ClearIcon />
							{failedResults}
						</Typography>
					</Box>
				</If>
			</ListItemButton>
		</ListItem>
	);
}

/*
function StatusLine({
	Icon,
	text,
	color,
}: {
	Icon: SvgIconComponent;
	text: string;
	color: string;
}) {
	return (
		<Typography variant="caption" color={color}>
			<Stack display="flex" flexDirection="row" alignItems="center">
				<Icon fontSize="small" />
				{text}
			</Stack>
		</Typography>
	);
}*/

function BottomBarIndicator({ children }) {
	const styles = useTestRunListItemBottomBarIndicatorStyles();
	return (
		<Typography variant="subtitle2" css={styles.bottomBarIndicator}>
			{children}
		</Typography>
	);
}
