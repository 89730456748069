import { useAllApplicationsPage } from '@src/modules/application/pages/AllApplicationsPage/hooks';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { LoadingCircularProgress } from '@src/common/components/LoadingCircularProgress/LoadingCircularProgress';
import Box from '@mui/material/Box';

import { useAllApplicationsPageStyles } from '@src/modules/application/pages/AllApplicationsPage/styles';
import { ApplicationsTable } from '@src/modules/application/components/ApplicationsTable/ApplicationsTable';
import { FormTextField } from '@src/common/components/FormTextField';
import { Cached } from '@mui/icons-material';

export const AllApplicationsPage = () => {
	const {
		applications,
		isLoading,
		searchTerm,
		handleChange,
		handleRefreshApplicationKeysCache,
	} = useAllApplicationsPage();

	const styles = useAllApplicationsPageStyles();

	return (
		<Box css={styles.section}>
			<Choose>
				<When condition={isLoading}>
					<LoadingCircularProgress>
						<Typography variant="h6">Loading applications...</Typography>
					</LoadingCircularProgress>
				</When>

				<Otherwise>
					<Box css={styles.topBar}>
						<FormTextField
							name="search"
							value={searchTerm}
							onChange={handleChange}
							label="Search application"
							placeholder="Application name"
							css={styles.searchField}
						/>
						<Tooltip title="Refresh cached keys for all applications">
							<IconButton onClick={handleRefreshApplicationKeysCache}>
								<Cached />
							</IconButton>
						</Tooltip>
					</Box>

					<ApplicationsTable applications={applications} />
				</Otherwise>
			</Choose>
		</Box>
	);
};
