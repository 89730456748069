import { useActiveApplication } from '@src/common/state/volatile/hooks';
import { setActiveApplication } from '@src/common/state/volatile/slice';
import { useGetApplicationsQueryState } from '@src/modules/settings/service';
import { useAppDispatch } from '@src/store/hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { testRunsRoutes } from '../../routes/routes';
import { TestSuiteRun } from '../../types';
import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';
import { invalidateAllTags } from '@src/api';

export const useTestRunList = (testRuns: TestSuiteRun[]) => {
	const activeApplication = useActiveApplication();
	const { data: applications } = useGetApplicationsQueryState();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const onClick = useCallback(
		(testRun) => {
			if (
				!testRun.customerApplication?.name ||
				testRun.customerApplication?.name === activeApplication?.name
			) {
				return;
			}
			const application = applications.find(
				(app) => app.name === testRun.customerApplication.name
			);
			dispatch(setActiveApplication(application));
			dispatch(setPersistentActiveApplicationId(application.id));
			dispatch(invalidateAllTags());
			navigate(testRunsRoutes.testRun.make({ id: testRun.id }));
		},
		[navigate, dispatch, applications, activeApplication]
	);

	const onAppNameClick = useCallback(
		(event, appName: string) => {
			event.preventDefault();
			event.stopPropagation();
			const application = applications.find((app) => app.name === appName);
			dispatch(setActiveApplication(application));
			dispatch(setPersistentActiveApplicationId(application.id));
			dispatch(invalidateAllTags());
			navigate(testRunsRoutes.testRuns.absolutePath);
		},
		[applications, dispatch, navigate]
	);

	// const waitingForActiveApplicationRef = useRef(undefined);

	// useEffect(() => {
	//     if (
	//         activeApplication &&
	//         activeApplication.name === waitingForActiveApplicationRef.current
	//     ) {
	//         waitingForActiveApplicationRef.current = undefined;
	//         navigate(testRunsRoutes.testRuns.absolutePath);
	//     }
	// }, [activeApplication, navigate]);
	return {
		onClick,
		onAppNameClick,
	};
};
