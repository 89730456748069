export type TestSuiteRun = {
	id: string;
	branch: string;
	commitHash: string;
	tags: string[];
	name: string; // this will be PR name or last commit message
	environment: string;
	mode: 'normal' | 'refactor' | 'auto-heal';
	pullRequestURL?: string;
	status: TestSuiteRunStatus;
	results: {
		passed: number | null;
		failed: number | null;
		healed: number | null;
	};
	uploadsCompleted: boolean;
	startedAt: string; // date
	endedAt: string; // date
	duration: number;
	isHidden?: boolean;
	customerApplication?: {
		id: string;
		name: string;
	};
	runtimeVersion: string;
};

export enum TestSuiteRunStatus {
	Running = 'running',
	Passed = 'passed',
	Failed = 'failed',
}

export type TestSuiteRunPagination = { page: number; resultsPerPage: number };
export type TestSuiteRunQuery = {
	order?: Record<string, unknown>;
	where?: Record<string, unknown>;
};

export type TestSuiteRunListOptions = {
	displayApplicationName: boolean;
	referrer: string;
};
