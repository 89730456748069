import {
	useGetAllPeriodicTestsQuery,
	useCreatePeriodicTestMutation,
} from '@src/modules/internal/api';
import { useCallback, useMemo, useState } from 'react';

export const usePeriodicTestsPage = () => {
	const {
		data: periodicTestRuns,
		refetch,
		// isLoading,
		// isError,
	} = useGetAllPeriodicTestsQuery();
	const [createPeriodicTest] = useCreatePeriodicTestMutation();

	const handleCreatePeriodicTest = useCallback(
		async (appName: string) => {
			try {
				const result = await createPeriodicTest({ appName }).unwrap();
				refetch();
				return result;
			} catch (error) {
				console.log('Failed to create periodic test');
			}
		},
		[createPeriodicTest, refetch]
	);

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [appName, setAppName] = useState('');
	const [isAddingApp, setIsAddingApp] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [searchQuery, setSearchQuery] = useState('');

	// Function to handle adding a new user
	const handleAddAppClick = useCallback(() => {
		setIsAddingApp(true);
	}, [setIsAddingApp]);

	// Function to handle canceling the add user action
	const handleCancelAddUser = useCallback(() => {
		setIsAddingApp(false);
		setAppName(''); // Clear email input when canceled
	}, [setIsAddingApp, setAppName]);

	// Function to show the snackbar
	const handleShowSnackbar = useCallback(
		(message: string) => {
			setSnackbarMessage(message);
			setSnackbarOpen(true);
		},
		[setSnackbarOpen, setSnackbarMessage]
	);

	// Function to handle creating the user
	const onCreatePeriodicTest = useCallback(async () => {
		if (appName) {
			const result = await handleCreatePeriodicTest(appName);
			if (result) {
				handleShowSnackbar('Periodic test created successfully!');
				setAppName('');
				setIsAddingApp(false);
				refetch();
			}
		}
	}, [appName, handleCreatePeriodicTest, refetch, handleShowSnackbar]);

	// Function to close the snackbar
	const handleCloseSnackbar = useCallback(() => {
		setSnackbarOpen(false);
	}, [setSnackbarOpen]);

	const filteredPeriodicTestRuns = useMemo(
		() =>
			(periodicTestRuns ?? []).filter((periodicTestRun) =>
				periodicTestRun.appName
					.toLowerCase()
					.includes(searchQuery.toLowerCase())
			),
		[periodicTestRuns, searchQuery]
	);

	return {
		snackbarOpen,
		appName,
		setAppName,
		isAddingApp,
		setIsAddingApp,
		snackbarMessage,
		searchQuery,
		setSearchQuery,
		handleAddAppClick,
		handleCancelAddUser,
		onCreatePeriodicTest,
		handleCloseSnackbar,
		filteredPeriodicTestRuns,
	};
};
