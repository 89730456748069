import { useGetTestRunsQuery } from '@src/modules/test-runs/api';
import {
	TestRunFilterNames,
	useFilterTestRuns,
} from '@src/modules/test-runs/pages/test-runs/hooks/useFilterTestRuns';
import {
	useGetApplicationsQueryState,
	useGetSettingsStatus,
} from '@src/modules/settings/service';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { setActiveApplication } from '@src/common/state/volatile/slice';
import { useAppDispatch } from '@src/store/hooks';
import { setPersistentActiveApplicationId } from '@src/common/state/persistent/slice';

export const useTestRuns = () => {
	const {
		data: testRuns,
		isSuccess: isTestRunsLoadingSuccess,
		isLoading: isTestRunsLoading,
	} = useGetTestRunsQuery();
	const { settingsStatus, isLoading: isSettingsStatusLoading } =
		useGetSettingsStatus();

	const [queryParams, setQueryParams] = useSearchParams();
	const appFromQuery = queryParams.get('app');

	const dispatch = useAppDispatch();

	const { data: applications } = useGetApplicationsQueryState();

	const needToSetAppRef = useRef(true);

	useEffect(() => {
		if (!needToSetAppRef.current || !applications) {
			return;
		}
		if (appFromQuery) {
			queryParams.delete('app');
			setQueryParams(queryParams, { replace: true });
			const application = applications?.find(
				(app) => app.name === appFromQuery
			);
			if (!application) {
				return;
			}
			dispatch(setPersistentActiveApplicationId(application.id));
			dispatch(setActiveApplication(application));
			setTimeout(() => window.location.reload(), 100);
			needToSetAppRef.current = false;
		}
	}, [appFromQuery, applications, dispatch, queryParams, setQueryParams]);

	const { filterAutocompleteInputsData, filteredTestRuns } = useFilterTestRuns({
		testRuns,
		excludeFilters: [TestRunFilterNames.App],
	});

	return {
		hasTestRuns: !!testRuns?.length,
		testRuns: filteredTestRuns,
		isLoading: isTestRunsLoading && isSettingsStatusLoading,
		filterAutocompleteInputsData,
		isTestRunsLoadingSuccess,
		setupIncomplete: !(
			settingsStatus?.hasValidEnvironmentSettings &&
			settingsStatus?.isGitIntegrated
		),
	};
};
