import { prepareModuleRoutes } from '@src/routing';
import { ApplicationRoutes } from '@src/app/router/routes';

type InternalRouteNames =
	| 'internal'
	| 'testGenerationLabeling'
	| 'applications'
	| 'support'
	| 'periodicTestRuns'
	| 'testRuns';

export const InternalApplicationRoutes =
	prepareModuleRoutes<InternalRouteNames>(
		{
			internal: {
				path: '/',
			},
			applications: {
				path: '/applications',
			},
			testGenerationLabeling: {
				path: '/test-generation-labeling',
				isRouter: true,
			},
			support: {
				path: '/support',
			},
			periodicTestRuns: {
				path: '/periodic-test-runs',
			},
			testRuns: {
				path: '/test-runs',
			},
		},
		ApplicationRoutes.internal.absolutePath
	);
