import AnalyticsProviderInterface from './providers/AnalyticsProviderInterface';
// import SegmentAnalyticsProvider from './providers/SegmentAnalyticsProvider';
// import GoogleTagManagerAnalyticsProvider from '@src/services/analytics/providers/GoogleTagManagerAnalyticsProvider';
import { FixedEventAttributesEnum } from '@src/services/analytics/types';
import Config from '@src/config';
import GoogleTagManagerAnalyticsProvider from './providers/GoogleTagManagerAnalyticsProvider';
// import ChecksumAnalyticsProvider from './providers/ChecksumAnalyticsProvider';

export const ANALYTICS_PROVIDER_CLASSES: (new () => AnalyticsProviderInterface)[] =
	[
		//SegmentAnalyticsProvider,
		GoogleTagManagerAnalyticsProvider,
		// ChecksumAnalyticsProvider,
	];

export const FIXED_EVENT_ATTRIBUTES: Partial<
	Record<FixedEventAttributesEnum, string | number>
> = {
	[FixedEventAttributesEnum.AppVersion]: Config.appVersion,
	[FixedEventAttributesEnum.GitVersionTag]: Config.git.versionTag,
};
