import { useCallback, useState, useEffect } from 'react';
import {
	useDeletePeriodicTestMutation,
	useExecutePeriodicTestMutation,
	useGetPeriodicTestQuery,
	useUpdatePeriodicTestMutation,
} from '../../../../api';
import { PeriodicTestRun } from '../../types';

export function usePeriodicTestRow({
	periodicTest: initialTest,
}: {
	periodicTest: PeriodicTestRun;
}) {
	const [periodicTest, setPeriodicTest] =
		useState<PeriodicTestRun>(initialTest);

	// Query + loading flags
	const {
		data,
		isFetching: isRefetching,
		isLoading: isQueryLoading,
		isError: isQueryError,
	} = useGetPeriodicTestQuery({ id: initialTest.id });

	// Mutations + loading flags
	const [updatePeriodicTest, { isLoading: isUpdating, error: updateError }] =
		useUpdatePeriodicTestMutation();
	const [deletePeriodicTest, { isLoading: isDeleting, error: deleteError }] =
		useDeletePeriodicTestMutation();
	const [executePeriodicTest, { isLoading: isExecuting, error: executeError }] =
		useExecutePeriodicTestMutation();

	/**
	 * Use local changes for purely in-memory updates (branch name, etc.)
	 */
	const handleLocalChange = useCallback(
		(
			changes:
				| Partial<PeriodicTestRun>
				| ((prev: PeriodicTestRun) => PeriodicTestRun)
		) => {
			if (typeof changes === 'function') {
				setPeriodicTest((prev) => changes(prev));
				return;
			} else {
				setPeriodicTest((prev) => ({ ...prev, ...changes }));
			}
		},
		[setPeriodicTest]
	);

	/**
	 * Commit to server
	 */
	const handleCommitChanges = useCallback(
		async (updatedTest: PeriodicTestRun) => {
			try {
				await updatePeriodicTest(updatedTest).unwrap();
			} catch (error) {
				console.error('Failed to commit changes', error);
				throw error;
			}
		},
		[updatePeriodicTest]
	);
	/**
	 * Execute test now
	 */
	const handleExecuteNow = useCallback(async () => {
		try {
			await executePeriodicTest(periodicTest).unwrap();
		} catch (error) {
			console.error('Failed to execute test', error);
		}
	}, [executePeriodicTest, periodicTest]);

	/**
	 * Delete test
	 */
	const handleDeletePeriodicTest = useCallback(async () => {
		try {
			await deletePeriodicTest({ id: periodicTest.id }).unwrap();
			// Optionally, handle post-deletion logic here
		} catch (error) {
			console.error('Failed to delete test', error);
		}
	}, [deletePeriodicTest, periodicTest]);

	/**
	 * Schedule job (activate)
	 */
	const handleScheduleJob = useCallback(async () => {
		try {
			await updatePeriodicTest({ ...periodicTest, isActive: true }).unwrap();
		} catch (error) {
			console.error('Failed to schedule job', error);
		}
	}, [updatePeriodicTest, periodicTest]);

	/**
	 * Pause job (deactivate)
	 */
	const handlePauseJob = useCallback(async () => {
		try {
			await updatePeriodicTest({ ...periodicTest, isActive: false }).unwrap();
		} catch (error) {
			console.error('Failed to pause job', error);
		}
	}, [updatePeriodicTest, periodicTest]);

	/**
	 * Sync local state with query data when it changes
	 */
	useEffect(() => {
		if (data) {
			setPeriodicTest(data);
		}
	}, [data]);

	/**
	 * Combined loading state
	 */
	const isLoading =
		isQueryLoading || // initial query load
		isRefetching || // re-fetching
		isUpdating || // updating
		isDeleting || // deleting
		isExecuting; // executing

	/**
	 * Aggregate errors
	 */
	const errorMessage = '';

	return {
		periodicTest,
		setPeriodicTest,
		handleLocalChange,
		handleCommitChanges,
		handleDeletePeriodicTest,
		handleExecuteNow,
		handleScheduleJob,
		handlePauseJob,

		// Expose the combined isLoading flag
		isLoading,
		// Expose the aggregated error message
		errorMessage,
		// Or individual flags if you prefer
		isUpdating,
		isExecuting,
		isDeleting,
		isQueryLoading,
		isRefetching,
		isQueryError,
		isUpdateError: !!updateError,
		isExecuteError: !!executeError,
		isDeleteError: !!deleteError,
	};
}
