import React from 'react';
import { Box, Typography } from '@mui/material';
import { TestRunsHeader } from '../../components/TestRunsHeader/TestRunsHeader';
import { TestRunList } from '../../components/TestRunList/TestRunList';
import { TestSuiteRun } from '../../types';
import { useTestRunsPageStyles } from './styles';
import { useTestRuns } from './hooks/useTestRuns';
import { TestRunsEmptyState } from '@src/modules/test-runs/pages/test-runs/components/TestRunsEmptyState/TestRunsEmptyState';

export const TestRuns = () => {
	const {
		hasTestRuns,
		testRuns,
		isLoading,
		filterAutocompleteInputsData,
		isTestRunsLoadingSuccess,
		setupIncomplete,
	} = useTestRuns();

	const styles = useTestRunsPageStyles();

	return (
		<Box component="section" css={styles.section}>
			<Typography variant="h1" gutterBottom>
				Test Runs
			</Typography>
			<If condition={!isLoading}>
				<Choose>
					<When condition={!hasTestRuns && isTestRunsLoadingSuccess}>
						<TestRunsEmptyState setupIncomplete={setupIncomplete} />
					</When>

					<Otherwise>
						<TestRunsHeader
							filterAutocompleteInputsData={filterAutocompleteInputsData}
						/>

						<TestRunList
							isTestRunsLoadingSuccess={isTestRunsLoadingSuccess}
							testRuns={testRuns as TestSuiteRun[]}
							listStyle={styles.list}
						/>
					</Otherwise>
				</Choose>
			</If>
		</Box>
	);
};
