import { TestSuiteRun, TestSuiteRunStatus } from '@src/modules/test-runs/types';
import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';

export enum TestRunFilterNames {
	Commit = 'commit',
	Tags = 'tags',
	Branch = 'branch',
	Status = 'status',
	App = 'app',
}

type TestRunFilter = {
	name: TestRunFilterNames;
	options: Set<string>;
	populateOptions: (testRun: TestSuiteRun) => void;
	isMatch: (testRun: TestSuiteRun, filter: string) => boolean;
	clearOptions: () => void;
};

type FiltersState = Record<TestRunFilterNames, string>;

class TestRunFilters {
	private availableFilterNames: TestRunFilterNames[] = [
		TestRunFilterNames.Commit,
		TestRunFilterNames.Tags,
		TestRunFilterNames.Branch,
		TestRunFilterNames.Status,
		TestRunFilterNames.App,
	];

	private makeFilter = (
		name: TestRunFilterNames,
		fields: Partial<TestRunFilter>
	) => {
		return {
			name,
			options: new Set<string>(),
			populateOptions: (testRun: TestSuiteRun) => {},
			isMatch: (testRun: TestSuiteRun, filter: string) => false,
			clearOptions: () => this.filters[name].options.clear(),
			...fields,
		};
	};

	private filters: Record<TestRunFilterNames, TestRunFilter> = {
		[TestRunFilterNames.Commit]: this.makeFilter(TestRunFilterNames.Commit, {
			populateOptions: (testRun) =>
				this.filters.commit.options.add(testRun.commitHash),
			isMatch: (testRun, filter) => testRun.commitHash === filter,
		}),
		[TestRunFilterNames.Tags]: this.makeFilter(TestRunFilterNames.Tags, {
			populateOptions: (testRun) =>
				testRun.tags.forEach((tag) => this.filters.tags.options.add(tag)),
			isMatch: (testRun, filter) => testRun.tags.includes(filter),
		}),
		[TestRunFilterNames.Branch]: this.makeFilter(TestRunFilterNames.Branch, {
			populateOptions: (testRun) =>
				this.filters.branch.options.add(testRun.branch),
			isMatch: (testRun, filter) => testRun.branch === filter,
		}),
		[TestRunFilterNames.Status]: this.makeFilter(TestRunFilterNames.Status, {
			options: new Set([
				TestSuiteRunStatus.Passed,
				TestSuiteRunStatus.Failed,
				TestSuiteRunStatus.Running,
			]),
			populateOptions: (testRun) => {},
			isMatch: (testRun, filter) => testRun.status === filter,
			clearOptions: () => {},
		}),
		[TestRunFilterNames.App]: this.makeFilter(TestRunFilterNames.App, {
			populateOptions: (testRun) => {
				if (testRun.customerApplication?.name) {
					this.filters.app.options.add(testRun.customerApplication?.name);
				}
			},
			isMatch: (testRun, filter) =>
				testRun.customerApplication?.name === filter,
		}),
	};

	private clearOptions() {
		this.availableFilterNames.forEach((filterName) => {
			this.filters[filterName].clearOptions();
		});
	}

	populateOptions(testRuns: TestSuiteRun[]) {
		this.clearOptions();
		testRuns.forEach((testRun) => {
			this.availableFilterNames.forEach((filterName) => {
				this.filters[filterName].populateOptions(testRun);
			});
		});
	}

	private isMatch(testRun: TestSuiteRun, filtersState: FiltersState) {
		const match = this.availableFilterNames.reduce((res, filterName) => {
			const filterValue = filtersState[filterName];

			if (filterValue === undefined) {
				return res && true;
			}

			return res && this.filters[filterName].isMatch(testRun, filterValue);
		}, true);
		return match;
	}

	filterTestRuns(testRuns: TestSuiteRun[], filtersState: FiltersState) {
		return testRuns.filter((testRun) => this.isMatch(testRun, filtersState));
	}

	private makeHandler =
		(
			filterName: TestRunFilterNames,
			setActiveFilters: Dispatch<SetStateAction<FiltersState>>
		) =>
		(e) => {
			setActiveFilters((prev) => ({
				...prev,
				[filterName]: e.target.outerText,
			}));
		};

	makeAutoCompleteData(
		activeFilters: FiltersState,
		setActiveFilters: Dispatch<SetStateAction<FiltersState>>,
		excludeFilters: TestRunFilterNames[] = []
	) {
		return this.availableFilterNames
			.filter((filterName) => !excludeFilters.includes(filterName))
			.map((filterName) => {
				return {
					name: filterName,
					onChange: this.makeHandler(filterName, setActiveFilters),
					options: Array.from(this.filters[filterName].options),
					value: activeFilters[filterName],
				};
			});
	}
}

export const useFilterTestRuns = ({
	testRuns,
	excludeFilters = [],
}: {
	testRuns: TestSuiteRun[];
	excludeFilters?: TestRunFilterNames[];
}) => {
	const [activeFilters, setActiveFilters] = useState<FiltersState>({
		commit: undefined,
		tags: undefined,
		branch: undefined,
		status: undefined,
		app: undefined,
	});
	const testRunFilters = useRef(new TestRunFilters());

	const filteredTestRuns = useMemo(() => {
		if (!testRuns) {
			return [];
		}

		return testRunFilters.current.filterTestRuns(testRuns, activeFilters);
	}, [activeFilters, testRuns, testRunFilters]);

	const previousTestRuns = usePrevious(testRuns);
	const filterAutocompleteInputsData = useMemo(() => {
		if (testRuns && testRuns !== previousTestRuns) {
			testRunFilters.current.populateOptions(testRuns);
		}

		return testRunFilters.current.makeAutoCompleteData(
			activeFilters,
			setActiveFilters,
			excludeFilters
		);
	}, [
		activeFilters,
		setActiveFilters,
		testRuns,
		previousTestRuns,
		excludeFilters,
	]);

	return {
		filterAutocompleteInputsData,
		filteredTestRuns,
		activeFilters,
	};
};
